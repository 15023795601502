<template>
    <div>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-start pa-5>
                <v-flex xs12>
                  <span class="heading">Rescuer Map</span>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <v-card flat>
                        <v-layout wrap justify-center pa-2>
                          <v-flex xs12 sm12 lg12>
                            <v-layout wrap justify-start pt-10>
                              <v-flex xs12>
                                <v-layout wrap justify-center>
                                  <v-flex xs12>
                                    <div id="map"></div>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
    <script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBpukNl1L1qGqEwad87A1-MkZj8H_bKDZM&libraries=visualization,drawing"></script>
    <script>
  var marker;
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        data: [],
        reportedimages: [],
        map: "",
        mapData: {},
        location: [],
        approveDialog: false,
        closeremarks: "",
        rejectDialog: false,
      };
    },
    beforeMount() {
      this.getData();
    },
    methods: {
      initMap() {
        var vm = this;
        var mapCanvas = document.getElementById("map");
  
        // Set default map options
        var mapOptions = {
        zoom: 8,
        center: new google.maps.LatLng(20.9517, 85.0985),
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        };
  
        // Create a new map
        vm.map = new google.maps.Map(mapCanvas, mapOptions);
  
        // Check if location data is available
        if (vm.data && vm.data.length > 0) {
          vm.data.forEach((user) => {
            if (user.location && user.location.length === 2) {
              const lat = user.location[1]; // Latitude
              const lon = user.location[0]; // Longitude
  
              // Create a marker at the location
              const marker = new google.maps.Marker({
                map: vm.map,
                position: new google.maps.LatLng(lat, lon),
                icon: {
                  url: require("@/assets/images/highthreat.png"), // Change the URL for a custom marker
                  scaledSize: new google.maps.Size(20, 20), // Adjust the size of the icon
                },
              });
  
              // Create an InfoWindow with user details
              const infoContent = `
    <div style="font-size: 14px; line-height: 1.5;">
      <p><strong>Name:</strong> <b>${user.name}</b></p>
      <p><strong>Email:</strong> <b>${user.email}</b></p>
      <p><strong>Phone:</strong> <b>${user.phone}</b></p>
    </div>
  `;
  
              const infowindow = new google.maps.InfoWindow({
                content: infoContent,
              });
  
              // Add a click event listener to the marker to open the InfoWindow
              marker.addListener("click", function () {
                infowindow.open(vm.map, marker);
              });
            }
          });
        }
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/users/rescuers/all",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.data = response.data.data;
                this.appLoading = false;
                this.msg = response.data.msg;
                this.showSnackBar = false;
                this.initMap();
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      closeoperation() {
        if (!this.closeremarks) {
          this.msg = "Please enter remarks.";
          this.showSnackBar = true;
          return;
        }
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/operation/closereport",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.$route.query.id,
            remarks: this.closeremarks,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = "Report Closed Successfully.";
                this.showSnackBar = true;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      formatDate(date) {
        if (!date) return "-";
        const [year, month, day] = date.slice(0, 10).split("-");
        return `${day}-${month}-${year}`;
      },
    },
  };
  </script>
          <style>
  .heading {
    font-family: poppinsregular;
    font-size: 20px;
    font-weight: 900;
  }
  .subheading {
    font-family: poppinsregular;
    font-size: 15px;
    font-weight: 500;
  }
  #map {
    height: 500px !important;
    width: 100%;
  }
  </style>